import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: 30,
  },
  path: {
    fill: '#7df3e1',
  },
});
const LogoFull = () => {
  const classes = useStyles();

  return (
<svg className={classes.svg} width="282px" height="70px" viewBox="0 0 282 70" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <title>Logo deliveri</title>
    <g id="Brand-Assets" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Artboard-Copy-10" transform="translate(-1463, -439)" fill="#000000" fill-rule="nonzero">
            <g id="Group" transform="translate(971, 408)">
                <g id="Logo-deliveri" transform="translate(492, 31)">
                    <path  d="M46.9789069,60.8306709 C46.4349274,60.8306709 46.0248506,60.9825788 45.6984629,61.1049491 C45.3637062,61.2273193 45.1000854,61.3285912 44.5602904,61.3285912 C43.3467976,61.3285912 43.2170794,59.3706673 43.2170794,58.5309542 L43.2170794,16.5790584 C43.2170794,10.9584665 43.3802733,8.12285249 43.4848847,6.24510218 C43.5392827,5.26614021 43.5811272,4.52769908 43.5811272,3.79769727 C43.5811272,0.3713304 41.2001708,0 40.1749787,0 C39.0451751,0 38.0450897,0.47682199 36.5345004,1.19416481 C35.6181042,1.62879016 34.4673783,2.1815661 32.9358668,2.79341732 L32.1450043,3.08457412 C30.4921435,3.67954669 28.6216909,4.35469287 28.6216909,6.59111459 C28.6216909,7.87389234 29.6426985,8.93724757 31.575918,9.61661342 C33.5216909,10.16095 33.6807003,10.8529749 33.6807003,11.5154621 L33.6807003,25.6513352 C33.6807003,25.8032431 33.6807003,25.9467117 33.6765158,26.0817409 C31.6637916,23.9508108 27.6969257,20.3683164 21.0059778,20.3683164 C10.5490179,20.3683164 0,28.3434806 0,46.1546808 C0,62.5396347 10.2184458,70 19.7087959,70 C24.9184458,70 29.5548249,68.2699379 33.1157985,64.9912593 C33.3877882,64.7338598 33.6263023,64.4975586 33.8355252,64.2823558 C35.2791631,67.8690699 37.9362938,69.6835252 41.7441503,69.6835252 C45.6105892,69.6835252 49,66.7719573 49,63.451082 C48.9916311,61.3792272 47.6735269,60.8306709 46.9789069,60.8306709 Z M33.806234,45.1630599 C33.806234,56.0118151 29.500427,62.2315993 21.9893254,62.2315993 C14.4196413,62.2315993 9.89624253,55.8008319 9.89624253,45.0280306 C9.89624253,34.4113569 14.6037575,27.5544035 21.8972673,27.5544035 C27.6550811,27.5544035 33.806234,32.1791549 33.806234,45.1630599 Z" id="Shape"></path>
                    <path d="M90.7736913,50.7983336 C89.7666571,50.7983336 88.8761392,51.5337528 88.0896538,53.0513518 C86.3335858,56.9622513 82.5884171,61.6255739 74.6944338,61.6255739 C64.9486727,61.6255739 61.5863435,52.9620813 61.2742461,49.0639347 C67.8116461,48.8726407 83.0128696,47.9714334 89.1923979,43.7969733 C91.3021763,42.3771467 92.288404,40.4982146 92.288404,37.8796123 C92.288404,27.3499405 84.8188731,20 74.1160133,20 C60.9371809,20 51,31.1885734 51,46.0244856 C51,60.1419827 60.1215664,70 73.1797212,70 C87.0118775,70 93,59.4873321 93,54.1225982 C93.0041473,50.9556198 91.1482082,50.7983336 90.7736913,50.7983336 Z M73.6333027,26.86958 C78.8598936,26.86958 82.8048047,31.2310831 82.8048047,37.0124129 C82.8048047,37.7775888 82.5800945,38.4024826 81.4898343,38.9763646 C77.457536,41.0550927 65.9681908,42.2028567 60.9080518,42.2411155 C60.8997292,42.1816018 60.8955679,42.1093351 60.8955679,42.0158136 C60.8997292,33.8071757 66.7297084,26.86958 73.6333027,26.86958 Z" id="Shape"></path>
                    <path d="M111.42342,63.0146199 C111.14534,62.8843202 110.854226,62.745614 110.567456,62.5816886 C109.576798,62.0100512 108.968499,61.2744883 108.968499,59.3788377 L108.968499,16.5144371 C108.968499,10.9157529 109.137954,8.09119152 109.246578,6.22076023 C109.303063,5.24561404 109.346513,4.51005117 109.346513,3.78289474 C109.346513,0.369883041 106.874212,0 105.809689,0 C104.679991,0 103.732783,0.449744152 102.298935,1.12646199 C101.360417,1.56779971 100.148164,2.14364035 98.4796872,2.78252924 L97.6584836,3.07255117 C95.9422116,3.66520468 94,4.3377193 94,6.56542398 C94,7.8810307 95.0427982,8.97386696 96.9285249,9.57913012 C98.9489463,10.121345 99.1140561,10.7644371 99.1140561,11.4243421 L99.1140561,59.0593933 C99.1140561,60.9508406 98.8229416,61.7494518 97.1370845,62.7330044 L96.6808603,62.9557749 C95.7553769,63.3887061 94.0434499,64.1999269 94.0434499,66.1207968 C94.0434499,66.9866594 94.4344992,69 98.0451879,69 C99.2444058,69 100.239409,68.9537646 101.225722,68.9075292 C103.072344,68.8234649 105.31436,68.8276681 107.404302,68.9201389 C108.33413,68.9621711 109.203128,69 109.902672,69 C113.291766,69 114,67.3859649 114,66.028326 C113.99131,64.2377558 112.505323,63.5316155 111.42342,63.0146199 Z" id="Path"></path>
                    <path d="M123.205988,16 C127.971856,16 132,12.2575995 132,7.82638671 C132,4.50517079 129.489222,2 126.165269,2 C121.969461,2 118,6.26888123 118,10.7834535 C118,13.9511125 120.045509,16 123.205988,16 Z" id="Path"></path>
                    <path d="M133.427765,62.9674748 C133.149685,62.8364248 132.85857,62.69692 132.571801,62.5320507 C131.581143,61.9571219 130.972844,61.2173238 130.972844,59.3107583 L130.972844,36.512294 C130.972844,31.0631524 131.142299,28.0405487 131.255268,26.0367527 C131.307408,25.0813562 131.346513,24.3838323 131.346513,23.8004486 C131.346513,20.6594772 129.447751,20 127.853139,20 C126.723441,20 125.758853,20.4565611 124.290245,21.1456302 C123.347382,21.5895091 122.130784,22.1602105 120.479687,22.802778 L119.502064,23.1451989 C117.859657,23.6947632 116,24.320421 116,26.5651799 C116,27.8883617 116.938518,28.9198516 118.9068,29.6765594 C120.944601,30.2599431 121.114056,31.008196 121.114056,31.7226296 L121.114056,59.0021568 C121.114056,60.9044949 120.822942,61.7077043 119.137085,62.69692 L118.68086,62.9209732 C117.755377,63.3563972 116.04345,64.1722888 116.04345,66.1042188 C116.04345,66.9750669 116.434499,69 120.045188,69 C122.191614,69 123.130133,68.9408162 123.955681,68.8900871 C124.57267,68.8520404 125.128829,68.818221 126.136867,68.818221 C127.440365,68.818221 128.348468,68.8647226 129.213122,68.9112242 C130.051705,68.9534984 130.851184,69 131.902672,69 C135.291766,69 136,67.3766716 136,66.0112156 C135.995655,64.1976534 134.514013,63.4874472 133.427765,62.9674748 Z" id="Path"></path>
                    <path d="M182,22.9398293 C182,21.1051028 180.799545,20.0128004 178.788783,20.0128004 C178.360049,20.0128004 177.558317,20.0597353 176.66655,20.110937 C175.624727,20.1706722 174.462858,20.2432079 173.695424,20.2432079 C173.155219,20.2432079 172.186281,20.1920063 171.178756,20.1365378 C170.038324,20.0768025 168.850731,20.0128004 168.14332,20.0128004 C166.334062,20.0128004 165.022137,21.3184431 165.022137,23.1190352 C165.022137,24.6550853 166.179718,25.4572449 167.028611,26.0417973 C168.001837,26.7116858 168.507743,27.1084988 168.507743,28.094131 C168.507743,29.5875131 168.036136,32.3780042 164.636276,42.5458029 C162.008137,50.3881923 160.323213,54.6549983 159.375711,56.6987983 C158.230991,54.1429815 156.215942,48.8948102 153.969376,43.0407524 C149.763496,31.985458 149.013212,29.4893765 149.013212,28.0002612 C149.013212,27.1938349 149.53198,26.6604842 150.385161,25.8839255 C151.14402,25.1927029 151.9972,24.4118774 151.9972,23.2043713 C151.9972,21.0837687 150.715286,20.0042668 148.185756,20.0042668 C147.19538,20.0042668 145.917753,20.0640021 144.682999,20.1237374 C142.303526,20.2389411 140.700061,20.2218739 139.079447,20.1152038 C138.174818,20.0597353 137.287339,20 136.39986,20 C134.367661,20 133,21.2672414 133,23.1531696 C133,25.0902995 134.526293,26.1314002 135.503806,26.7970219 C137.475982,27.9703936 138.937965,31.5630442 140.481407,35.351968 L143.148132,42.0039185 C147.812757,53.6522989 152.031499,64.1699756 152.46452,65.1257402 C153.287689,67.0287356 154.132295,69 156.859043,69 C158.625427,69 161.082072,67.8820968 162.201067,66.580721 C163.821682,64.7801289 165.099309,61.7250958 166.33835,58.772466 L166.582728,58.1921804 C166.749934,57.782567 167.380173,56.045977 168.263365,53.5968304 C170.454195,47.5464995 174.527168,36.2821317 176.670837,31.2643678 C177.957039,28.2221351 179.256103,27.0572971 180.203605,26.2082027 C181.078222,25.4273772 182,24.6038837 182,22.9398293 Z" id="Path"></path>
                    <path d="M220.773691,50.7983336 C219.766657,50.7983336 218.876139,51.5337528 218.089654,53.0513518 C216.333586,56.9622513 212.588417,61.6255739 204.694434,61.6255739 C194.948673,61.6255739 191.586343,52.9620813 191.274246,49.0639347 C197.811646,48.8726407 213.01287,47.9714334 219.192398,43.7969733 C221.302176,42.3771467 222.288404,40.4982146 222.288404,37.8796123 C222.288404,27.3499405 214.818873,20 204.116013,20 C190.937181,20 181,31.1885734 181,46.0244856 C181,60.1419827 190.121566,70 203.179721,70 C217.011877,70 223,59.4873321 223,54.1225982 C223.004147,50.9556198 221.144047,50.7983336 220.773691,50.7983336 Z M203.629141,26.86958 C208.855732,26.86958 212.800643,31.2310831 212.800643,37.0124129 C212.800643,37.7775888 212.575933,38.4024826 211.485673,38.9763646 C207.453375,41.0550927 195.96403,42.2028567 190.903891,42.2411155 C190.895568,42.1816018 190.891407,42.1093351 190.891407,42.0158136 C190.895568,33.8071757 196.729708,26.86958 203.629141,26.86958 Z" id="Shape"></path>
                    <path d="M252.357695,20 C245.599454,20 240.649579,24.9131073 238.395431,27.2343602 C238.323937,26.8157736 238.260855,26.3633618 238.206183,25.9743722 C238.088429,25.162568 237.962264,24.2873414 237.802455,23.4797653 C237.381905,21.0020709 235.712321,20.6342221 234.761878,20.6342221 C233.533871,20.6342221 232.230166,21.1923376 230.585815,21.8984382 C229.963401,22.1648115 229.273699,22.4607818 228.512503,22.7609802 L227.591498,23.099232 C226.014435,23.6658038 224.050466,24.3634481 224.050466,26.6043662 C224.050466,27.3781172 224.399523,28.7860903 226.792453,29.5894383 C228.90782,30.1813789 228.90782,30.6845284 228.90782,31.2637846 L228.90782,59.0004314 C228.90782,60.9496074 228.638668,61.7191302 226.973289,62.7085167 C226.834508,62.7888515 226.678904,62.8649581 226.510684,62.9495211 C225.63594,63.3850203 224,64.1968246 224,66.103719 C224,66.9747174 224.378495,69 227.873267,69 C229.950784,69 230.854967,68.9408059 231.658218,68.8900682 C232.255399,68.8520148 232.793703,68.8181897 233.769379,68.8181897 C235.253921,68.8181897 236.772107,68.8689274 238.18095,68.9154371 C239.425779,68.9577185 240.582291,68.9957719 241.553762,68.9957719 C244.829848,68.9957719 245.515344,67.3721633 245.515344,66.0064717 C245.515344,63.9727328 243.450443,63.2750884 242.083655,62.8142204 C241.738804,62.6958323 241.402364,62.5859004 241.099568,62.4505997 C239.047283,61.5584606 238.450102,60.8354474 238.450102,59.2202951 L238.450102,39.0985417 C238.450102,34.8788506 243.54717,28.6465614 248.122755,28.6465614 C249.788134,28.6465614 250.839509,29.7670205 251.247443,31.9656571 C251.331553,32.4349814 251.373608,32.8577962 251.411457,33.2298732 C251.554444,34.5786522 251.768925,36.6123911 254.561378,36.6123911 C258.228575,36.6123911 261,33.1875917 261,28.6465614 C261,22.7060143 256.521141,20 252.357695,20 Z" id="Path"></path>
                    <path d="M279.427765,62.9674748 C279.149685,62.8364248 278.85857,62.69692 278.571801,62.5320507 C277.581143,61.9571219 276.972844,61.2173238 276.972844,59.3107583 L276.972844,36.512294 C276.972844,31.0631524 277.142299,28.0405487 277.255268,26.0367527 C277.307408,25.0813562 277.346513,24.3838323 277.346513,23.8004486 C277.346513,20.6594772 275.447751,20 273.853139,20 C272.723441,20 271.754508,20.4565611 270.2859,21.1498576 C269.343037,21.5937365 268.130784,22.1644379 266.479687,22.802778 L265.502064,23.1451989 C263.859657,23.6947632 262,24.320421 262,26.5651799 C262,27.8883617 262.938518,28.9198516 264.9068,29.6765594 C266.944601,30.2599431 267.114056,31.008196 267.114056,31.7226296 L267.114056,59.0021568 C267.114056,60.9044949 266.822942,61.7077043 265.137085,62.69692 L264.68086,62.9209732 C263.755377,63.3563972 262.04345,64.1722888 262.04345,66.1042188 C262.04345,66.9750669 262.434499,69 266.045188,69 C268.191614,69 269.130133,68.9408162 269.955681,68.8900871 C270.57267,68.8520404 271.128829,68.818221 272.136867,68.818221 C273.440365,68.818221 274.348468,68.8647226 275.213122,68.9112242 C276.051705,68.9534984 276.851184,69 277.902672,69 C281.291766,69 282,67.3766716 282,66.0112156 C281.995655,64.1976534 280.509668,63.4874472 279.427765,62.9674748 Z" id="Path"></path>
                    <path d="M270.205988,16 C274.971856,16 279,12.2575995 279,7.82638671 C279,4.50517079 276.489222,2 273.165269,2 C268.969461,2 265,6.26888123 265,10.7834535 C265.004192,13.9511125 267.045509,16 270.205988,16 Z" id="Path"></path>
                </g>
            </g>
        </g>
    </g>
</svg>
   
  );
};

export default LogoFull;
