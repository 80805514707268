import {
    createBaseThemeOptions,
    createUnifiedTheme,
    genPageTheme,
    palettes,
    shapes,
  } from '@backstage/theme';
  
  
  export const deliveryTheme = createUnifiedTheme({
    ...createBaseThemeOptions({
      palette: {
        ...palettes.light,
        primary: {
          main: '#000',
        },
        secondary: {
          main: '#565a6e',
        },
        error: {
          main: '#FEFE73',
        },
        warning: {
          main: '#8f5e15',
        },
        info: {
          main: '#34548a',
        },
        success: {
          main: '#485e30',
        },
        background: {
          default: '#d5d6db',
          paper: '#d5d6db',
        },
        banner: {
          info: '#34548a',
          error: '#FEFE73',
          text: '#000',
          link: '#565a6e',
        },
        errorBackground: '#FEFE73',
        warningBackground: '#8f5e15',
        infoBackground: '#000',
        navigation: {
          background: '#FEFE73',
          indicator: '#8f5e15',
          color: '#000',
          selectedColor: '#8f5e15',
        },
      },
    }),
    defaultPageTheme: 'home',
    // fontFamily: 'Comic Sans MS',
    /* below drives the header colors */
    pageTheme: {
      home: genPageTheme({ colors: ['#FEFE73', '#000'], shape: shapes.wave, options: { fontColor: '#000' } }),
      documentation: genPageTheme({
        colors: ['#FEFE73', '#000'],
        shape: shapes.wave2,
      }),
      tool: genPageTheme({ colors: ['#FEFE73', '#000'], shape: shapes.round }),
      service: genPageTheme({
        colors: ['#FEFE73', '#000'],
        shape: shapes.wave,
      }),
      website: genPageTheme({
        colors: ['#FEFE73', '#000'],
        shape: shapes.wave,
      }),
      library: genPageTheme({
        colors: ['#FEFE73', '#000'],
        shape: shapes.wave,
      }),
      other: genPageTheme({ colors: ['#FEFE73', '#000'], shape: shapes.wave }),
      app: genPageTheme({ colors: ['#FEFE73', '#000'], shape: shapes.wave }),
      apis: genPageTheme({ colors: ['#FEFE73', '#000'], shape: shapes.wave }),
    },
  });